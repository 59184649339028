import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Em, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy"} />
		<Helmet>
			<title>
				Sikeres Kommunikáció - Adatvédelem
			</title>
			<meta name={"description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:title"} content={"Sikeres Kommunikáció - Sziklay Bence"} />
			<meta property={"og:description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/mesterkulcs.webp?v=2024-03-07T19:53:21.189Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar />
		<Section padding="80px 0 80px 0" quarkly-title="Content">
			<Override slot="SectionContent" lg-padding="0px 0 0px 0px" />
			<Text margin="0px 0px 0px 0px" font="--headline1">
				Adatvédelmi nyilatkozat
				<br />
				{"\n\n"}
			</Text>
			<Text margin="30px 0px 0px 0px">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatkezelési tájékoztató célja
				</Strong>
				<br />
				<br />
				DIGITAL NEURONS LTD. kötelezőnek ismeri el jelen adatkezelési tájékoztatót a természetes személyek személyes adatainak védelméről és az adatok szabad áramlásáról kapcsolatban. Kötelezettséget vállal arra, hogy tevékenységével kapcsolatos minden adatkezelés megfelel a jelen szabályzatban és a hatályos nemzeti jogszabályokban – tekintettel a 2011. évi CXII. törvény (Info tv.) tartalmára -, valamint az Európai Parlament és Tanács (EU) 2016/679 rendeletében meghatározott elvárásoknak megfelelően.
				<br />
				<br />
				A DIGITAL NEURONS LTD. adatkezeléseivel kapcsolatosan felmerülő adatvédelmi irányelvek folyamatosan elérhetők a https://sziklaybence.com/adatvedelmi-tajekoztato/ címen.
				<br />
				<br />
				DIGITAL NEURONS LTD. fenntartja magának a jogot jelen tájékoztató bármikor történő megváltoztatására.
				<br />
				<br />
				Amennyiben kérdése lenne jelen közleményünkhöz kapcsolódóan, kérjük, írja meg nekünk, és kollégáink megválaszolják kérdését.
				<br />
				<br />
				A DIGITAL NEURONS LTD. elkötelezett az ügyfelei és partnerei személyes adatainak védelmében, kiemelten fontosnak tartja ügyfelei információs önrendelkezési jogának tiszteletben tartását. DIGITAL NEURONS LTD. a személyes adatokat bizalmasan kezeli, és megtesz minden olyan biztonsági, technikai és szervezési intézkedést, mely az adatok biztonságát garantálja.
				<br />
				<br />
				DIGITAL NEURONS LTD. az alábbiakban ismerteti adatkezelési gyakorlatát:
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Szolgáltató, adatkezelő adatai
				</Strong>
				<br />
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					2.1  Az adatkezelő megnevezése:
				</Em>
				<br />
				<br />
				Név / cégnév: DIGITAL NEURONS LTD.
				<br />
				Székhely: 71-75, Shelton StreetCovent Garden, London, WC2H9JQ, United Kingdom
				<br />
				Company’s house number: 11368828
				<br />
				Weboldal megnevezése, címe:{" "}
				<Link
					href="https://sziklaybence.com"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					https://sziklaybence.com
				</Link>
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Netlify –{" "}
				</Strong>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Link
						href="https://netlify.com/"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						https://netlify.com/
						<br />
					</Link>
				</Strong>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatok tárolása a Netflify szerverein történik az EURÓPAI GDPR SZABÁLYOK BETARTÁSÁNAK MEGFELELŐEN.
				</Strong>
				<br />
				<br />
				<br />
				<br />
				Az adatkezelési tájékoztató elérhetősége:{" "}
				<Link href="/terms">
					https://sziklaybence.com/terms/
				</Link>
				<br />
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					2.2  Az adatkezelő elérhetőségei:
				</Em>
				<br />
				<br />
				Levelezési cím:{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					71-75, SHELTON STREETCOVENT GARDEN, WC2H 9JQ, LONDON, UNITED KINGDOM
				</Strong>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
				</Strong>
				Telefonszám : +44 7 308 026 136
				<br />
				E-mail cím: info@sziklaybence.com
				<br />
				Kapcsolattartó neve: Sziklay Bence
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Fogalom meghatározások
				</Strong>
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„személyes adat”:
				</Strong>
				{" "}azonosított vagy azonosítható természetes személyre („érintett”) vonatkozó bármely információ; azonosítható az a természetes személy, aki közvetlen vagy közvetett módon, különösen valamely azonosító, például név, szám, helymeghatározó adat, online azonosító vagy a természetes személy testi, fiziológiai, genetikai, szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező alapján azonosítható;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„adatkezelés”
				</Strong>
				: a személyes adatokon vagy adatállományokon automatizált vagy nem automatizált módon végzett bármely művelet vagy műveletek összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás, tárolás, átalakítás vagy megváltoztatás, lekérdezés, betekintés, felhasználás, közlés továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás, korlátozás, törlés, illetve megsemmisítés;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„adatkezelő”
				</Strong>
				: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely a személyes adatok kezelésének céljait és eszközeit önállóan vagy másokkal együtt meghatározza; ha az adatkezelés céljait és eszközeit az uniós vagy a tagállami jog határozza meg, az adatkezelőt vagy az adatkezelő kijelölésére vonatkozó különös szempontokat az uniós vagy a tagállami jog is meghatározhatja;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„adatfeldolgozó”
				</Strong>
				: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az adatkezelő nevében személyes adatokat kezel;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„címzett”
				</Strong>
				: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a személyes adatot közlik, függetlenül attól, hogy harmadik fél-e. Azon közhatalmi szervek, amelyek egy egyedi vizsgálat keretében az uniós vagy a tagállami joggal összhangban férhetnek hozzá személyes adatokhoz, nem minősülnek címzettnek; az említett adatok e közhatalmi szervek általi kezelése meg kell, hogy feleljen az adatkezelés céljainak megfelelően az alkalmazandó adatvédelmi szabályoknak;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„harmadik fél”
				</Strong>
				: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem azonos az érintettel, az adatkezelővel, az adatfeldolgozóval vagy azokkal a személyekkel, akik az adatkezelő vagy adatfeldolgozó közvetlen irányítása alatt a személyes adatok kezelésére felhatalmazást kaptak;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„az érintett hozzájárulása”
				</Strong>
				: az érintett akaratának önkéntes, konkrét és megfelelő tájékoztatáson alapuló és egyértelmű kinyilvánítása, amellyel az érintett nyilatkozat vagy a megerősítést félreérthetetlenül kifejező cselekedet útján jelzi, hogy beleegyezését adja az őt érintő személyes adatok kezeléséhez;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„adatvédelmi incidens”
				</Strong>
				: a biztonság olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„az adatkezelés korlátozása”
				</Strong>
				: a tárolt személyes adatok megjelölése jövőbeli kezelésük korlátozása céljából;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„vállalkozás”
				</Strong>
				: gazdasági tevékenységet folytató természetes vagy jogi személy, függetlenül a jogi formájától, ideértve a rendszeres gazdasági tevékenységet folytató személyegyesítő társaságokat és egyesületeket is;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„profilalkotás”
				</Strong>
				: személyes adatok automatizált kezelésének bármely olyan formája, amelynek során a személyes adatokat valamely természetes személyhez fűződő bizonyos személyes jellemzők értékelésére, különösen a munkahelyi teljesítményhez, gazdasági helyzethez, egészségi állapothoz, személyes preferenciákhoz, érdeklődéshez, megbízhatósághoz, viselkedéshez, tartózkodási helyhez vagy mozgáshoz kapcsolódó jellemzők elemzésére vagy előre jelzésére használják;
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					„álnevesítés”
				</Strong>
				: a személyes adatok olyan módon történő kezelése, amelynek következtében további információk felhasználása nélkül többé már nem állapítható meg, hogy a személyes adat mely konkrét természetes személyre vonatkozik, feltéve hogy az ilyen további információt külön tárolják, és technikai és szervezési intézkedések megtételével biztosított, hogy azonosított vagy azonosítható természetes személyekhez ezt a személyes adatot nem lehet kapcsolni;
				<br />
				<br />
				Kérdőív: a hallgatóság adatait nem csak kapcsolattartásra használja a DIGITAL NEURONS LTD., hanem minőségbiztosítási okokból, azaz jogos gazdasági érdekből kérdőívezésre is.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatkezelés irányelvei
				</Strong>
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatkezelő kijelenti, hogy az adatkezelési tájékoztatóban foglaltak szerint végzi a személyes adatok kezelését és betartja a vonatkozó jogszabályok előírásait, különös figyelemmel az alábbiakra:
				</Strong>
				<br />
				<br />
				<br />
				<br />
				A személyes adatok kezelését jogszerűen és tisztességesen, valamint az érintett számára átlátható módon kell végezni.
				<br />
				<br />
				A személyes adatok gyűjtése csak meghatározott, egyértelmű és jogszerű célból történhet.
				<br />
				<br />
				A személyes adatok kezelésének célja megfelelő és releváns legyen, és csak a szükséges mértékű lehet.
				<br />
				<br />
				A személyes adatoknak pontosnak és naprakésznek kell lenniük. A pontatlan személyes adatokat haladéktalanul törölni kell.
				<br />
				<br />
				A személyes adatok tárolásának olyan formában kell történnie, hogy az érintettek azonosítását csak szükséges ideig tegye lehetővé. A személyes adatok ennél hosszabb ideig történő tárolására csak akkor kerülhet sor, ha a tárolás közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból történik.
				<br />
				<br />
				A személyes adatok kezelését oly módon kell végezni, hogy megfelelő technikai vagy szervezési intézkedések alkalmazásával biztosítva legyen a személyes adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével, megsemmisítésével vagy károsodásával szembeni védelmet is ideértve.
				<br />
				<br />
				Az adatvédelem elveit minden azonosított vagy azonosítható természetes személyre vonatkozó információ esetében alkalmazni kell.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Fontos adatkezelési információk
				</Strong>
				<br />
				<br />
				Az adatkezelés célja, hogy a szolgáltató / adatkezelő a weboldal működtetése során az oldalon regisztrált személyek részére megfelelő többletszolgáltatást nyújthasson. Az adatkezelés jogalapja az érintett személy hozzájárulása.
				<br />
				<br />
				Az adatkezelésben érintettek köre a weboldal regisztrációs felhasználói.
				<br />
				<br />
				Az adatkezelés időtartama és az adatok törlése. Az adatkezelés időtartama mindig a konkrét felhasználói cél függvénye, de az adatokat haladéktalanul törölni kell, ha az eredetileg kitűzött cél már megvalósult. Az adatkezeléshez történő hozzájárulását az érintett személy bármikor visszavonhatja a kapcsolattartási e-mail címre küldött levélben. Amennyiben a törlésnek jogszabályi akadálya nincs, ez esetben adatai törlésre kerülnek.
				<br />
				<br />
				Az adatok megismerésére jogosultak az adatkezelő és alkalmazottai.
				<br />
				<br />
				Az érintett személy kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatokhoz való hozzáférést, azok helyesbítését, törlését vagy kezelésének korlátozását, és tiltakozhat az ilyen személyes adatok kezelése ellen, valamint az érintett adathordozhatósághoz való jogáról.
				<br />
				<br />
				Az érintett személy bármely időpontban visszavonhatja az adatkezelési hozzájárulását, de ez nem érinti a visszavonás előtt a hozzájárulás alapján végrehajtott adatkezelés jogszerűségét.
				<br />
				<br />
				Az érintett személy élhet a felügyeleti hatósághoz címzett panasz benyújtásának jogával.
				<br />
				<br />
				Amennyiben az érintett személy használni kívánja a regisztráció nyújtotta előnyöket, azaz igénybe kívánja venni a weboldal ez irányú szolgáltatását, szükséges a kért személyes adatok megadása. Az érintett személy nem köteles személyes adatokat megadni, az adatszolgáltatás elmaradása számára semmilyen hátrányos következménnyel nem jár. A weboldal egyes funkcióinak igénybevétele azonban regisztráció nélkül nem lehetséges.
				<br />
				<br />
				Az érintett személy jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül helyesbítse, illetve kiegészítse a rá vonatkozó pontatlan személyes adatokat.
				<br />
				<br />
				Az érintett személy jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó pontatlan személyes adatokat, az adatkezelő pedig köteles arra, hogy az érintettre vonatkozó személyes adatokat indokolatlan késedelem nélkül törölje, amennyiben az adatkezelésnek nincs más jogalapja.
				<br />
				<br />
				A személyes adatok módosítása vagy törlése kezdeményezhető e-mailben, telefonon vagy levélben a fentebb megadott elérhetőségi lehetőségeken.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Weboldalon történő regisztráció
				</Strong>
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés célja
				</Strong>
				{" "}többletszolgáltatás nyújtása és kapcsolatfelvétel.
				<br />
				<br />
				A regisztrációs{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés jogalapja
				</Strong>
				{" "}az Ön hozzájárulása.
				<br />
				<br />
				Az adatkezelésben{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					érintettek köre
				</Strong>
				{" "}a weboldal regisztrációs felhasználói.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés időtartama:
				</Strong>
				{" "}az adatkezelés a hozzájárulás visszavonásáig történik. Az adatkezeléshez történő hozzájárulását Ön bármikor visszavonhatja a kapcsolattartási e-mail címre küldött levélben.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok törlése
				</Strong>
				{" "}az adatkezeléshez történő hozzájárulás visszavonásakor történik meg. Ön bármikor visszavonhatja az adatkezeléshez történő hozzájárulását a kapcsolattartási e-mail címre küldött levélben.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok megismerésére jogosultak
				</Strong>
				{" "}az adatkezelő és alkalmazottai.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok tárolási módja
				</Strong>
				: elektronikus.
				<br />
				<br />
				A{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					személyes adatok módosítása vagy törlése
				</Strong>
				{" "}kezdeményezhető e-mailben, telefonon vagy levélben a fentebb megadott elérhetőségi lehetőségeken.
				<br />
				<br />
				A személyes adatok megadása feltétlenül szükséges az adatbázisokban történő azonosítás és a kapcsolattartás miatt. A pontos cégnév és cím a számlázáshoz szükséges, amely jogszabályi kötelezettség.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Kezelt adatok köre
				</Strong>
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatkezelési adatok konkrét célja
				</Strong>
				<br />
				<br />
				Név
				<br />
				<br />
				Azonosítás, kapcsolattartás, számlázás.
				<br />
				<br />
				Cégnév
				<br />
				<br />
				Azonosítás, kapcsolattartás, számlázás.
				<br />
				<br />
				Cím
				<br />
				<br />
				Azonosítás, kapcsolattartás, számlázás.
				<br />
				<br />
				E-mail
				<br />
				<br />
				Azonosítás, kapcsolattartás.
				<br />
				<br />
				Telefon
				<br />
				<br />
				Azonosítás, kapcsolattartás.
				<br />
				<br />
				Regisztráció időpontja, IP cím
				<br />
				<br />
				Technikai információs művelet.
				<br />
				<br />
				Kérdőív küldése
				<br />
				<br />
				Kapcsolattartás, visszajelzés.
				<br />
				<br />
				A felhasználó adatkezelési hozzájárulását a weboldalon található és kifejezetten erre szolgáló üres checkbox szándékos kipipálásával teheti meg.
				<br />
				<br />
				Ön, mint érintett személy tiltakozhat a személyes adatai kezelése ellen, e tekintetben a fentebb részletezett adatkezelési információk és ezen tájékoztató, valamint a tájékoztatóban ismertetett jogszabályok szerinti eljáráshoz jogosult.
				<br />
				<br />
				Az Adatkezelő a személyes adatok kezeléséhez a szolgáltatás nyújtása során alkalmazott informatikai eszközöket úgy választja meg és üzemelteti, hogy a kezelt adat:
				<br />
				<br />
				<br />
				<br />
				az arra feljogosítottak számára hozzáférhető (
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					rendelkezésre állás
				</Strong>
				);
				<br />
				<br />
				hitelessége és hitelesítése biztosított (
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés hitelessége
				</Strong>
				);
				<br />
				<br />
				változatlansága igazolható (
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatintegritás
				</Strong>
				);
				<br />
				<br />
				a jogosulatlan hozzáférés ellen védett (
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adat bizalmassága
				</Strong>
				) legyen.
				<br />
				<br />
				az adatokat megfelelő intézkedésekkel védi a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés ellen.
				<br />
				<br />
				Az Adatkezelő az adatkezelés során megőrzi az adatokra vonatkoztatva:
				<br />
				<br />
				<br />
				<br />
				a{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					titkosságot
				</Strong>
				: megvédi az információt, hogy csak az férhessen hozzá, aki erre jogosult;
				<br />
				<br />
				a{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					sértetlenséget
				</Strong>
				: megvédi az információnak és a feldolgozás módszerének a pontosságát és teljességét;
				<br />
				<br />
				a{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					rendelkezésre állást
				</Strong>
				: gondoskodik arról, hogy amikor a jogosult használónak szüksége van rá, valóban hozzá tudjon férni a kívánt információhoz, és rendelkezésre álljanak az ezzel kapcsolatos eszközök
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Hírlevél
				</Strong>
				<br />
				<br />
				A weblap üzemeltetőjeként kijelentjük, hogy az általunk megjelentetett információk és ismertetők során maradéktalanul betartjuk a vonatkozó jogszabályi rendelkezéseket. Kijelentjük továbbá, hogy hírlevélre történő feliratkozáskor nem áll módunkban a kapcsolattartási adatok valódiságának ellenőrzése és annak megállapítása sem, hogy a megadott adatok magánszemélyre vagy vállalkozásra vonatkoznak. A velünk kapcsolatba lépő vállalkozásokat ügyfél partnerként kezeljük.
				<br />
				<br />
				Az adatkezelés célja szakmai ismertetők, reklámot tartalmazó elektronikus üzenetek, információk, hírlevelek küldése, amelyekről bármikor következmények nélkül leiratkozhat. Ön akkor is minden következmény nélkül leiratkozhat, ha vállalkozása időközben megszűnt, a vállalkozásból kilépett, vagy valaki az Ön kapcsolattartási adatait közölte velünk.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés jogalapja
				</Strong>
				{" "}az Ön, mint érintett hozzájárulása. Tájékoztatjuk, hogy a felhasználó előzetesen és kifejezetten hozzájárulhat ahhoz, hogy a szolgáltató reklámajánlataival, tájékoztatásaival és egyéb küldeményeivel a regisztrációkor megadott e-mail címen megkeresse. Ennek következtében a felhasználó hozzájárul ahhoz, hogy a Szolgáltató e célból a szükséges személyes adatait kezelje.
				<br />
				<br />
				Tájékoztatjuk, ha hírlevelet szeretne kapni tőlünk, a szükséges adatokat köteles megadni. Az adatszolgáltatás elmaradása esetén nem tudunk Önnek hírlevelet küldeni.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					érintetti kör
				</Strong>
				{" "}regisztráció, hírlevél feliratkozás esetén: minden olyan természetes személy, aki a Vállalkozás hírlevelére feliratkozni kíván, illetve a honlapon regisztrálni kíván és hozzájárulását adja személyes adatainak kezeléséhez.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés célja
				</Strong>
				{" "}hírlevél feliratkozás esetén: az érintett tájékoztatása a Vállalkozás szolgáltatásairól, termékeiről, az azokban bekövetkezett változásokról, tájékoztatás hírekről, eseményekről.
				<br />
				<br />
				Regisztráció esetén: kapcsolatfelvétel szerződéskötés előkészítése végett, a honlapon ingyenesen elérhető szolgáltatások nyújtása érintett részére, hozzáférés a weboldal nem nyilvános tartalmaihoz.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés időtartama
				</Strong>
				. Az adatkezelés a hozzájárulás visszavonásáig történik. Az adatkezeléshez történő hozzájárulását Ön bármikor visszavonhatja a kapcsolattartási e-mail címre küldött levélben. Hírlevél feliratkozás esetén a leiratkozásig (az elektronikus levelekben elhelyezett leiratkozási linkre kattintva), regisztráció esetén az érintett kérelmére történő törlésig.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok törlése
				</Strong>
				{" "}az adatkezeléshez történő hozzájárulás visszavonásakor történik meg. Ön bármikor visszavonhatja az adatkezeléshez történő hozzájárulását a kapcsolattartási e-mail címre küldött levélben.  Ugyancsak visszavonható a hozzájárulás a kiküldött hírlevelekben megjelenő link alapján.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok megismerésére jogosultak
				</Strong>
				{" "}az adatkezelő és alkalmazottai valamint a Vállalkozás weblapjának üzemeltetését ellátó adatfeldolgozó munkatársai.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok tárolási módja
				</Strong>
				: elektronikus.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok módosítása vagy törlése
				</Strong>
				{" "}kezdeményezhető e-mailben, telefonon vagy levélben a fentebb megadott elérhetőségi lehetőségeken.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Kezelt adatok köre
				</Strong>
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatkezelési adatok konkrét célja
				</Strong>
				<br />
				<br />
				Név
				<br />
				<br />
				Azonosítás, kapcsolattartás.
				<br />
				<br />
				E-mail
				<br />
				<br />
				Azonosítás, kapcsolattartás.
				<br />
				<br />
				Feliratkozás időpontja
				<br />
				<br />
				Technikai információs művelet.
				<br />
				<br />
				IP cím
				<br />
				<br />
				Technikai információs művelet.
				<br />
				<br />
				Tájékoztatjuk, hogy sem a felhasználónév, sem az e-mail cím esetében nem szükséges, hogy az a személyére utaló adatot tartalmazzon. Így például nem szükséges, hogy a felhasználónév vagy az e-mail cím az Ön nevét tartalmazza. Ön teljesen szabadon dönt arról, hogy ha olyan felhasználó nevet vagy e-mail címet ad meg, amely az Ön kilétére utaló információt tartalmaz. Az e-mail cím – ami a kapcsolattartást szolgálja – feltétlenül szükséges ahhoz, hogy az Ön részére küldött hírlevél vagy szakmai információ célba érjen.
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Üzenet küldése a weboldalról
				</Strong>
				<br />
				<br />
				A weboldalon lehetőség van a vállalkozás által nyújtott szolgáltatásokkal kapcsolatban ajánlat illetőleg egyéb információ kérésére, melynek során az érdeklődő néhány személyes adatának megadása után online üzenet formájában kérhet információt.
				<br />
				<br />
				Az érintetti kör online üzenet küldés esetén: minden olyan természetes személy, aki a Vállalkozó részéről valamilyen kérdésére információt, választ kér.  A weboldalon történő üzenet elküldésével az érintett hozzájárulását adja személyes adatainak kezeléséhez.
				<br />
				<br />
				A{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					kezelt adatok köre
				</Strong>
				{" "}a weblapról történő online üzenet küldés esetén a megadott személyes adatok: név, e-mail cím, telefonszám.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Kezelt adatok köre
				</Strong>
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatkezelési adatok konkrét célja
				</Strong>
				<br />
				<br />
				Név
				<br />
				<br />
				Azonosítás, kapcsolattartás.
				<br />
				<br />
				E-mail
				<br />
				<br />
				Azonosítás, kapcsolattartás.
				<br />
				<br />
				Feliratkozás időpontja
				<br />
				<br />
				Technikai információs művelet.
				<br />
				<br />
				IP cím
				<br />
				<br />
				Technikai információs művelet.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés célja:
				</Strong>
				{" "}az érintett személyre szabott kiszolgálása és tájékoztatása az általa feltett kérdésre fókuszálva a Vállalkozó által.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok címzettjei
				</Strong>
				{" "}(akik megismerhetik az adatokat) online üzenet küldés esetén: a Szakszervezet vezetője, ügyfélkapcsolatot ellátó munkatárs, a Vállalkozó weblapjának üzemeltetését ellátó adatfeldolgozó munkatársai.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés időtartama
				</Strong>
				: a feltett kérdés megválaszolásáig. Ezután a Vállalkozó mint adatkezelő, az ezen a csatornán érkező üzeneteket törli.
				<br />
				<br />
				Az érintett még az általa elküldött információ kérésre adott válasz előtt, kérheti személyes adatai törlését. Ez a Vállalkozó részére a kapcsolattartási e-mail címre küldött elektronikus levél formájában, vagy a Vállalkozó székhelyére megküldött postai levélben történhet. Ebben az esetben az érintett nem kap választ a kérdésére.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Sütik (cookie-k)
				</Strong>
				<br />
				<br />
				A sütiket a meglátogatott weboldalak helyezik el a felhasználó számítógépén és olyan információt tartalmaznak, mint például az oldal beállításai vagy a bejelentkezés állapota.
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					A sütik feladata:
				</Strong>
				<br />
				<br />
				<br />
				<br />
				információkat gyűjtenek a látogatókról és eszközeikről;
				<br />
				<br />
				megjegyzik a látogatók egyéni beállításait, amelyek felhasználásra kerül(het)nek online tranzakciók igénybevételekor, így nem kell újra begépelni őket;
				<br />
				<br />
				megkönnyítik a weboldal használatát;
				<br />
				<br />
				minőségi felhasználói élményt biztosítanak.
				<br />
				<br />
				A cookie-k tehát a felkeresett weboldalak által létrehozott kis fájlok. A böngészési adatok mentésével javítják a felhasználói élményt. A cookie-k segítségével a weboldal emlékezik a webhely beállításaira, és helyileg releváns tartalmakat kínál fel.
				<br />
				<br />
				A testre szabott kiszolgálás érdekében a felhasználó számítógépén kis adatcsomagot, ún. sütit (cookie) helyez el és a későbbi látogatás során olvas vissza. Ha a böngésző visszaküld egy korábban elmentett sütit, a sütit kezelő szolgáltatónak lehetősége van összekapcsolni a felhasználó aktuális látogatását a korábbiakkal, de kizárólag a saját tartalma tekintetében. Erről a szolgáltató a weblap látogatóját tájékoztatja.
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Feltétlenül szükséges, munkamenet (session) cookie:
				</Strong>
				<br />
				<br />
				Ezen sütik célja, hogy a látogatók maradéktalanul és zökkenőmentesen böngészhessék DIGITAL NEURONS LTD. weboldalát, használhassák annak funkcióit, és az ott elérhető szolgáltatásokat. Az ilyen típusú sütik egy látogatásra korlátozódnak, érvényességi idejük a munkamenet (böngészés) befejezéséig tart, a böngésző bezárásával a sütik e fajtája automatikusan törlődik a számítógépről, illetve a böngészésre használt más eszközről.
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Állandó cookie:
				</Strong>
				<br />
				<br />
				Ezek a cookie-k a látogatásával kapcsolatos preferenciákat tárolják és lehetővé teszik számunkra, hogy felismerjük, amikor újra ellátogat a DIGITAL NEURONS LTD. oldalaira. Addig maradnak a böngészőben, amíg ki nem törli őket vagy amíg le nem járnak.
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Harmadik fél által elhelyezett cookie-k (analitika)
				</Strong>
				<br />
				<br />
				A  DIGITAL NEURONS LTD. weboldalán alkalmazza a Google Analytics mint harmadik fél sütijeit is. Ezek a cookie-k nem a DIGITAL NEURONS LTD. köthetők és akár több weboldalon és munkameneten átívelő böngészési tevékenységet is magukban foglalhatnak.
				<br />
				<br />
				A Google Analytics statisztikai célú szolgáltatás használatával, információkat gyűjt azzal kapcsolatban, hogy a látogatók hogyan használják a weboldalakat. Az adatot a honlap fejlesztésének és a felhasználói élmény javításának céljával használja fel. Ezen sütik szintén lejáratukig a látogató számítógépén vagy böngészésre használt más eszközén, annak böngészőjében maradnak, illetve amíg a látogató nem törli őket. A Google Analytics adatkezelését a 13. fejezetben részletesen olvashatja.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelésben érintettek{" "}
				</Strong>
				köre a weboldal látogatói.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés célja
				</Strong>
				{" "}többletszolgáltatás, azonosítás, a látogatók nyomon követése.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés jogalapja:
				</Strong>
				{" "}a felhasználó hozzájárulása nem szükséges, amennyiben a sütik használatához a szolgáltatónak feltétlenül szüksége van.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok köre
				</Strong>
				: egyedi azonosítószám, időpont, beállítási adatok.
				<br />
				<br />
				A felhasználónak lehetősége van arra, hogy a sütiket a Beállítások menüpontban bármikor törölje a böngészőkből.
				<br />
				<br />
				Az adatok{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					megismerésére jogosult
				</Strong>
				{" "}adatkezelők. A sütik használatával nem kezel személyes adatokat az adatkezelő.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok tárolási módja
				</Strong>
				: elektronikus.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Közösségi oldalak
				</Strong>
				<br />
				<br />
				A közösségi oldal egy médiaeszköz, ahol az üzenetet közösségi felhasználókon keresztül terjesztik. A közösségi média az internetet és az online megjelenési lehetőségeket használja annak érdekében, hogy a felhasználók tartalombefogadókból tartalomszerkesztővé váljanak.
				<br />
				<br />
				A közösségi média az internetes alkalmazások olyan felülete, amelyen a felhasználók által létrehozott tartalom található, például Facebook, Google+, Twitter, stb.
				<br />
				<br />
				A közösségi média megjelenési formái lehetnek nyilvános beszédek, előadások, bemutatók, termékek vagy szolgáltatások ismertetése.
				<br />
				<br />
				A közösségi médiában megjelent információk formái lehetnek fórumok, blog bejegyzések, kép-, videó-, és hanganyagok, üzenőfalak, e-mail üzenetek, stb.
				<br />
				<br />
				A fentieknek megfelelően a kezelt adatok köre a személyes adatokon túl lehet a felhasználó nyilvános profilképe is.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					érintettek köre
				</Strong>
				: valamennyi regisztrált felhasználó.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatgyűjtés célja
				</Strong>
				{" "}a weboldal vagy a hozzá kapcsolódó weblap népszerűsítése.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés jogalapja
				</Strong>
				{" "}az érintett önkéntes hozzájárulása.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés időtartama
				</Strong>
				: az adott közösségi oldalon megtekinthető szabályozás szerint.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok törlési határideje
				</Strong>
				: az adott közösségi oldalon megtekinthető szabályozás szerint.
				<br />
				<br />
				Az adatok{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					megismerésére jogosultak
				</Strong>
				: az adott közösségi oldalon megtekinthető szabályozás szerint.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezeléssel kapcsolatos jogok
				</Strong>
				: az adott közösségi oldalon megtekinthető szabályozás szerint.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatok tárolási módja
				</Strong>
				: elektronikus.
				<br />
				<br />
				Fontos figyelembe venni, hogy amikor a felhasználó feltölt vagy beküld valamilyen személyes adatot, világszerte érvényes engedélyt ad a közösségi oldal üzemeltetőjének arra, hogy az ilyen tartalmakat tárolhassa és felhasználhassa. Ezért nagyon fontos meggyőződni arról, hogy a közzétett információ közléséhez a felhasználó teljes jogosultsággal rendelkezik-e.
				<br />
				<br />
				Az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény 20. § (1) bekezdése alapján, meg kell határozni a webshop weboldal adattovábbítási tevékenysége körében a következőket:
				<br />
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					10.1   Facebook
				</Em>
				<br />
				<br />
				Az adatgyűjtés ténye, a{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					kezelt adatok köre
				</Strong>
				: Facebook.com közösségi oldalon regisztrált neve, illetve a felhasználó nyilvános profilképe.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					érintettek köre
				</Strong>
				: Valamennyi érintett, aki regisztrált a Facebook.com közösségi oldalon, és lájkolta a weboldalt.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés célja
				</Strong>
				: A Facebook.com oldalon, a www.ondomainneve.hu egyes tartalmi elemeinek, termékeinek, akcióinak, vagy magának a weboldalnak a megosztása, illetve lájkolása, népszerűsítése.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés időtartama
				</Strong>
				, az adatok megismerésére jogosult lehetséges adatkezelők személye és az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: Az adatok forrásáról, azok kezeléséről, illetve az átadás módjáról, és jogalapjáról a http://www.facebook.com/about/privacy/ címen tájékozódhat az érintett.
				<br />
				<br />
				Az adatkezelés a Facebook.com weboldalon valósul meg, így az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés időtartamára
				</Strong>
				, módjára, illetve az adatok törlési és módosítási lehetőségeire a facebook.com közösségi oldal szabályozása vonatkozik:  (http://www.facebook.com/legal/terms?ref=pf),
				<br />
				<br />
				(
				<Link
					href="https://web.archive.org/web/20230326124156/https://www.facebook.com/about/privacy/"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					http://www.facebook.com/about/privacy/
				</Link>
				)
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés jogalapja
				</Strong>
				: az érintett önkéntes hozzájárulása személyes adatai kezeléséhez a Facebook.com weboldalon
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Google Analytics
				</Strong>
				<br />
				<br />
				Weboldalunk a Google Analytics alkalmazást{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					használja
				</Strong>
				.{"           "}
				<br />
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					11.1{"    "}
				</Em>
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Google Analytics alkalmazása esetén:
				</Em>
				<br />
				<br />
				A Google Analytics belső cookie-k (sütik) alapján állít össze jelentést ügyfelei részére a weblap felhasználóinak szokásairól.
				<br />
				<br />
				A weboldal üzemeltetőjének megbízásából a Google az információkat arra használja, hogy kiértékelje, hogyan használják a felhasználók a honlapot. További szolgáltatásként a honlap aktivitásával összefüggő jelentéseket készít weboldal üzemeltetőjének részére, hogy az további szolgáltatásokat teljesíthessen.
				<br />
				<br />
				Az adatokat a Google szerverei kódolt formátumban tárolják, hogy megnehezítsék és elejét vegyék az adatokkal való visszaélésnek.
				<br />
				<br />
				A Google Analytics letiltása az alábbiak szerint történhet. Idézet az oldalról:
				<br />
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Azok a webhelyfelhasználók, akik nem szeretnék, hogy a Google Analytics JavaScript jelentést készítsen az adataikról, telepíthetik a Google Analytics letiltó böngészőbővítményét. A bővítmény megtiltja a Google Analytics JavaScript (ga.js, analytics.js, és dc.js) számára, hogy információkat küldjön a Google Analytics rendszernek. A böngészőbővítmény a legtöbb újabb böngészőben használható. A Google Analytics letiltó böngészőbővítménye nem akadályozza meg az adatküldést maga a webhely és más internetes elemzési szolgáltatások részére.
				</Em>
				{" "}
				<br />
				<br />
				https://support.google.com/analytics/answer/6004245?hl=hu
				<br />
				<br />
				A Google adatvédelmi irányelvei:{" "}
				<Link
					href="https://web.archive.org/web/20230326124156/https://policies.google.com/privacy?hl=hu"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					https://policies.google.com/privacy?hl=hu
				</Link>
				<br />
				<br />
				Az adatok felhasználásával és védelmével kapcsolatos információk részletesen a fenti hivatkozásokon érhetők el.
				<br />
				<br />
				Adatvédelem részletesen:
				<br />
				<br />
				https://static.googleusercontent.com/media/www.google.com/en//intl/hu/policies/privacy/google_privacy_policy_hu.pdf
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Remarketing – Google Adwords
				</Strong>
				<br />
				<br />
				A weboldal a Google Adwords remarketing követő kódjait használja. A remarketing egy olyan funkció, amelynek segítségével a weboldal azoknak a felhasználóknak, akik korábban már felkeresték webhelyet, releváns hirdetéseket jelenítsen meg, miközben a Google Display
				<br />
				<br />
				Hálózat egyéb webhelyeit böngészik. A remarketing kód cookie-kat használ a látogatók megjelöléséhez. A webáruházat felkereső felhasználók letilthatják ezeket a cookie-kat, és egyéb, a Goggle adatkezelésével kapcsolatos információt is olvashatnak a következő címeken:
				<br />
				<br />
				<Link
					href="https://web.archive.org/web/20230326124156/https://www.google.hu/policies/technologies/ads/"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					http://www.google.hu/policies/technologies/ads/
				</Link>
				{"  "}
				<Link
					href="https://web.archive.org/web/20230326124156/https://support.google.com/analytics/answer/2700409"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					https://support.google.com/analytics/answer/2700409
				</Link>
				.
				<br />
				<br />
				Amennyiben felhasználó letiltja a remarketing cookie-kat, számukra nem fognak megjelenni személyre szabott ajánlatok a weboldaltól.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Adatfeldolgozók
				</Strong>
				<br />
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					13.1    Tárhely szolgáltató:
				</Em>
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Kinsta – www.kinsta.com{" "}
				</Strong>
				<Link
					href="https://web.archive.org/web/20230326124156/https://kinsta.com/knowledgebase/google-cloud-data-center-locations/"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						https://kinsta.com/knowledgebase/google-cloud-data-center-locations/
					</Strong>
				</Link>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					{" "}Az adatok tárolása a Kinsta Frankfurti szerverén történik.
				</Strong>
				<br />
				<br />
				{" "}
				<br />
				<br />
				Az Ön által megadott adatokat a tárhely szolgáltató által üzemeltetett szerver tárolja. Az adatokhoz csak munkatársaink, illetve a szervert üzemeltető munkatársak férhetnek hozzá, de mindannyian felelősséggel tartoznak az adatok biztonságos kezeléséért.
				<br />
				<br />
				A{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					tevékenység megnevezése
				</Strong>
				: tárhely szolgáltatás, szerverszolgáltatás.{" "}
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés célja
				</Strong>
				: a weboldal működésének biztosítása.
				<br />
				<br />
				A{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					kezelt adatok
				</Strong>
				: az érintett által megadott személyes adatok
				<br />
				<br />
				Az adatkezelés időtartama és az adatok törlésének határideje. Az adatkezelés a weboldal működésének végéig, illetve a weboldal működtetője és a tárhely szolgáltató közötti szerződéses megállapodás szerint. Az érintett személy szükség esetén a tárhely szolgáltatót megkeresve is kérheti adatai törlését.
				<br />
				<br />
				Az{" "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					adatkezelés jogalapja
				</Strong>
				{" "}az érintett személy hozzájárulása, illetve jogszabályon alapuló adatkezelés.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatkezeléssel kapcsolatos jogok
				</Strong>
				<br />
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					14.1     A tájékoztatás kéréshez való jog
				</Em>
				<br />
				<br />
				Ön a megadott elérhetőségeken keresztül tájékoztatást kérhet tőlünk, hogy cégünk milyen adatait, milyen jogalapon, milyen adatkezelési cél miatt, milyen forrásból, mennyi ideig kezeli. Az Ön kérelmére haladéktalanul, de legfeljebb 30 napon belül, az Ön által megadott e-mail elérhetőségre tájékoztatást küldünk.
				<br />
				<br />
				14.2.    A helyesbítéshez való jog
				<br />
				<br />
				Ön a megadott elérhetőségeken keresztül kérheti tőlünk, hogy valamely adatát módosítsuk. Erről az Ön kérelmére haladéktalanul, de legfeljebb 30 napon belül intézkedünk, az Ön által megadott e-mail elérhetőségre tájékoztatást küldünk.
				<br />
				<br />
				14.3     A törléshez való jog
				<br />
				<br />
				Ön a megadott elérhetőségeken keresztül kérheti tőlünk adatának törlését. Az Ön kérelmére ezt haladéktalanul, de legfeljebb 30 napon belül megtesszük, az Ön által megadott e-mail elérhetőségre tájékoztatást küldünk.
				<br />
				<br />
				14.4     A zároláshoz való jog
				<br />
				<br />
				Ön a megadott elérhetőségeken keresztül kérheti tőlünk adatának zárolását. A zárolás addig tart, amíg az Ön által megjelölt indok szükségessé teszi az adatok tárolását. Az Ön kérelmére ezt haladéktalanul, de legfeljebb 30 napon belül megtesszük, az Ön által megadott e-mail elérhetőségre tájékoztatást küldünk.
				<br />
				<br />
				14.5     A tiltakozáshoz való jog
				<br />
				<br />
				Ön a megadott elérhetőségeken keresztül tiltakozhat az adatkezelés ellen. A tiltakozást a kérelem benyújtásától számított legrövidebb időn belül, de legfeljebb 15 napon belül megvizsgáljuk, annak megalapozottsága kérdésében döntést hozunk, és a döntéséről Önt e-mailben tájékoztatjuk.
				<br />
				<br />
				14.6     Az adatkezeléssel kapcsolatos jogérvényesítési lehetőség
				<br />
				<br />
				Az adatkezelő esetleges jogsértése ellen panasszal a Nemzeti Adatvédelmi és Információszabadság Hatóságnál lehet élni:
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Nemzeti Adatvédelmi és Információszabadság Hatóság (NAIH):
				</Strong>
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Postacím:
				</Strong>
				{" "}1530 Budapest, Pf.: 5.
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Cím:
				</Strong>
				{" "}1125 Budapest, Szilágyi Erzsébet fasor 22/c
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Telefon:
				</Strong>
				{" "}+36 (1) 391-1400
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Fax:
				</Strong>
				{" "}+36 (1) 391-1410
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					E-mail:
				</Strong>
				{" "}ugyfelszolgalat@naih.hu
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					URL:{" "}
				</Strong>
				{" "}
				<Link
					href="https://web.archive.org/web/20230326124156/https://naih.hu/"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					https://naih.hu
				</Link>
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Az adatkezelés alapjául szolgáló jogszabályok
				</Strong>
				<br />
				<br />
				<br />
				<br />
				AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016. április 27.) a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi rendelet).
				<br />
				<br />
				évi CXII. törvény az információs önrendelkezési jogról és az információszabadságról.
				<br />
				<br />
				A köziratokról, a közlevéltárakról és a magánlevéltári anyag védelméről szóló 1995. évi LXVI. törvény.
				<br />
				<br />
				A közfeladatot ellátó szervek iratkezelésének általános követelményeiről szóló 335/2005. (XII. 29.) Korm. rendelet.
				<br />
				<br />
				évi CVIII. törvény az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről.
				<br />
				<br />
				évi C. törvény az elektronikus hírközlésről.
				<br />
				<br />
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Egyéb rendelkezések
				</Strong>
				<br />
				<br />
				{" "}A fentiekben említett adatokat kizárólag arra a célra használjuk fel, melyből a felhasználónk azt megadta, és semmilyen esetben nem adjuk át harmadik félnek, kivéve a hatóságokat – bíróság, ügyészség, nyomozó hatóság, szabálysértési hatóság, közigazgatási hatóság, Nemzeti Adatvédelmi és Információs Hatóság vagy jogszabály felhatalmazása alapján egyéb hatóság -, amelyek részére a pontos cél és az adatok körének megjelölésével kötelesek vagyunk kiadni azokat.{"\n\n"}
			</Text>
		</Section>
		<Components.FooterGlobal>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.FooterGlobal>
		<RawHtml>
			<script
				async={true}
				src={"https://consent.cookiebot.com/uc.js\" data-cbid=\"31a484bd-cc88-4f20-b7e1-45c91f621450"}
				type={"text/javascript"}
				place={"endOfBody"}
				rawKey={"65735175df6a3caf3b62d9ea"}
			/>
		</RawHtml>
	</Theme>;
});